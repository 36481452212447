// extracted by mini-css-extract-plugin
export var customEnterpriseDevBenefitsSection = "R_jc";
export var customEnterpriseDevCallToActionSection = "R_h7";
export var customEnterpriseDevCaseSection = "R_jk";
export var customEnterpriseDevCostSection = "R_jd";
export var customEnterpriseDevExperienceSection = "R_jj";
export var customEnterpriseDevExpertiseSection = "R_jh";
export var customEnterpriseDevIndustriesSection = "R_h6";
export var customEnterpriseDevPracticesSection = "R_jf";
export var customEnterpriseDevPrimeSection = "R_h4";
export var customEnterpriseDevProcessSection = "R_h9";
export var customEnterpriseDevProjLogoSection = "R_jg";
export var customEnterpriseDevQuotesSection = "R_jl";
export var customEnterpriseDevServicesSection = "R_h5";
export var customEnterpriseDevSolutionSection = "R_h8";
export var customEnterpriseDevTechSection = "R_jb";